addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

// When rails turbolinks ready
document.addEventListener("turbolinks:load", () => {

  file_form = document.getElementById('design_request_reply_files');
  if (file_form) {

    var draft_translations = {
      'da': 'Udkast',
      'de': 'Entwurf',
      'en': 'Draft',
      'at': 'Entwurf',
      'be': 'Visuel',
      'fr': 'Visuel',
      'sv': 'Utkast',
      'no': 'Utkast',
      'ch': 'Entwurf',
      'it': 'Bozza',
      'es': 'Borrador'
    };

    file_form.addEventListener("change", function(event) {

      file_name_div = document.getElementById('file-name-forms');
      $(file_name_div).html('');

      file_form.files.forEach(function (file, index) {
        var version =draft_translations[iso_code] + file.name.substring(
          file.name.lastIndexOf(" "),
          file.name.lastIndexOf(".")
        );
        var field = '<div class="form-group file-name-input-js">';
            field += '<label class="d-block">' + 'Version <em>' + file.name + '</em></label>';
            field += '<input type="text" class="form-control form-control-sm w-auto d-inline-block mr-2" name="design_request_reply[file_input][' + file.name + '][filename]" value="' + version + '">';
            field += approved_radio.replace("value", 'value="' + file.name + '"');
            field += '</div>';
            
            console.log();


        $(file_name_div).append(field);
      });
    });
  }

});

