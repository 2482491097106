// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "core-js/stable";

import Rails from "@rails/ujs"
Rails.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

import "bootstrap";

require("packs/direct_uploads.js");

Turbolinks.scroll = {};

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    $('.carousel').carousel({
        interval: false
      })
    // JQuery stuff


    // Link on table row
    $('.table-tr-click tr[data-url] td').click(function() {
      if (this.classList.contains('exclude-link') == false) {
        Turbolinks.visit(this.parentElement.dataset.url);
      }
    });

    $('[data-url]').click(function() {
      if (this.classList.contains('exclude-link') == false) {
        Turbolinks.visit(this.dataset.url);
      }
    });

    $(".search-customers-input").keyup(function() {
      if ($(this).val().length > 0) {
        $.ajax({
          url: '/customers/search',
          data: $(this).serialize(),
          success: function(data) {
            if (data.length > 0) {
             $('.search-customers-results').html(data);
             $('.search-customers-empty').hide();
            } else {
              $('.search-customers-results').html('');
              $('.search-customers-empty').show();
            }
           }
       });
      } else {
        $('.search-customers-results').html('');
        $('.search-customers-empty').show();
      }
    });

    // Change input from ajax results
    $('#search-customer-dropdown').on('click', '.dropdown-item.pointer', function (){
      var name = $(this).children('span').text();
      var id = this.dataset.id;
      var team_id = this.dataset.teamid;

      $('.search-customer-fake-input').val(name);
      $('#task_customer_id').val(id);

      var team_name = $('#task_team_id option[value="' + team_id + '"]:first').text();
      $('#task_team_id').children('optgroup').remove();
      $('#task_team_id').prepend('<optgroup label="Customers team"><option value="' + team_id + '" selected>' + team_name + '</option></optgroup>');
    });

    // Get event action and autofill task form
    $('select#task_event_action_id').change(function() {
      $.ajax({
        url: '/event_actions/actions_json',
        data: {
          id:  $(this).val()
        },
        dataType: 'json',
        success: function(data) {
          $.each(data, function( key, value ) {
            $('#task_' + key).val(value);
          });
        }
      })
    });


    // Autofill design reply
    $("select#autofill_design_reply").change(function() {
      if (this.value == undefined || this.value == "") {
        return;
      } else if (this.value == "help") {
        $('#design_request_reply_receiver').val(cc_mails);
        $('#design_request_reply_cc').val('');
        
        const subject = 'Need your help with ' + design_title;
        let content = 'Can you take care of this design request from ' + shop + '.<br><br>';
        content += '<strong>Customer:</strong> ' + contact_email
        content += '<br><br><strong>Instructions</strong></br>' + document.getElementById('instructions').innerHTML;
        content += '<br><strong>Product</strong><br>' + product + '';

        $('.email-subject').val(subject);
        $('.email-content').val(content);
        return;
      }

      Rails.ajax({
        url: '/settings/email_templates/' + this.value + '.json',
        type: "get",
        success: function(response) {
          var content = shortcodeHandler(response.content.body);
          $('#design_request_reply_receiver').val(contact_email);
          $('.email-content').val(content.replace(/&amp;#39;/g, "'"));
          
          if (order_reference.length > 0) {
            $('.email-subject').val(response.subject + ' ' + order_reference);
          } else {
            $('.email-subject').val(response.subject + ' ' + reference);
          }
          $('#design_request_reply_cc').val(cc_mails);
        },
        error: function(data) {}
      });
      
      $(this).blur(); // remove focus
    });


    $('[data-copy]').click(function(e) {
      e.preventDefault();
      $('.tooltip-inner').html('&nbsp;&nbsp;' + 'Copied!' + '&nbsp;&nbsp;');
      setClipboard(this.dataset.copy);
    });

    function setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
  }

});


// Replace [link][/link] shortcode in text with html link
function shortcodeHandler(content) {

  const shortcode_regex_link = /\[link (.*?)\](?:(.+?)?\[\/link\])?/;
  var regex_response = content.match(shortcode_regex_link);

  if (regex_response != null && regex_response.length > 2) {
    var link = '<a ' + regex_response[1] + '>' + regex_response[2] + '</a>';
    link = link.replace(/review_link/g , design_review_link);
    content = content.replace(regex_response[0], link);
  }

  return content
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")